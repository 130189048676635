@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: "DM Sans", sans-serif;
    }
  }

  html{
    background-color: #121212;
    /* cursor:url("./images/magic-cursor.png") 10 16,
    auto; */
    overflow: hidden;
  }

  @layer utilities{
    .shining-text{
      -webkit-mask-image: linear-gradient(-75deg, rgba(235, 235, 235, 0.3) 30%, #000 50%, rgba(235, 235, 235, 0.3) 70%);
    -webkit-mask-size: 200%;
    animation: shine 2.5s infinite;
    mask-clip: text;
  }
  .shining-text.get-in-touch{
    -webkit-mask-image: none;
  }
  }

/* SHINING TEXT */

.shining-text{
    -webkit-mask-image: linear-gradient(-75deg, rgba(235, 235, 235, 0.3) 30%, #000 50%, rgba(235, 235, 235, 0.3) 70%);
  -webkit-mask-size: 200%;
  animation: shine 2.5s infinite;
  mask-clip: text;
}

.shining-text.get-in-touch:hover{
  -webkit-mask-image: none;
}

@keyframes shine {
    from {
      -webkit-mask-position: 150%;
    }
    
    to {
      -webkit-mask-position: -50%;
    }
  }


/* ARROW ANIMATION */

.arrow-animation{
  will-change: transform;
  
    top:0px;
    right: -60px;
    animation: arrow .8s linear infinite alternate;
}

@keyframes arrow {
    to {
        /* top: 0px;
        right: -60px; */
        transform: translate(5px, -5px);
    }
  }

  /* ------ START ANIMATIONS ------ */

  /* CTA */
  .start-anim-cta{
    transform: translateX(-100%);

    will-change: transform;

    animation-name: CTA-in;
    /* DELAY */
    animation-delay: 1.3s;
    animation-duration: 1.6s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.72,0,0.32,0.99);
  }

  @keyframes CTA-in{
    from{
      transform: translateX(-100%);
    }
    to{
      transform: translateX(0%);
    }
  }

    /* SERVICES */
    .start-anim-services{
      will-change: transform;

      transform: translateX(70%);
      opacity: 0;
  
      animation-name: services-in;
      /* DELAY */
      animation-delay: 1.2s;
      animation-duration: 16s;
      animation-fill-mode: forwards;
    }

    @keyframes services-in{
      from{
        transform: translateX(70%);
        opacity: 0;
      }
      to{
        transform: translateX(0%);
        opacity: 1;
      }
    }

  